<template>
  <div
    :class="{
      wrapper: !device,
      wrappermobile: device,
    }"
  >
    <menu-ver type="profesor" :content="courses" :id_emp="id_emp" v-on:theme="selectTheme"/>
    <div class="container m-3">
      <div
        :class="{
          tableheader: !device,
          tableheadermobile: device,
        }"
      >
        <!--Comisiones-->
        <h1>
          {{ $t("profesor.wallet.detailC") }}
        </h1>
        <table class="table" v-if="courseComi && courseComi.length > 0">
          <thead>
            <tr>
              <th scope="col">{{ $t("profesor.wallet.course") }}</th>
              <th style="text-align: center;" scope="col">{{ $t("profesor.wallet.price") }}</th>
              <th style="text-align: center;" scope="col">{{ $t("profesor.wallet.students") }}</th>
              <th style="text-align: center;" scope="col">{{ $t("profesor.wallet.total") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in courseComi" :key="item.curso_id">
              <td>{{ item.titulo }}</td>
              <td style="text-align: center;">{{ item.precio }}</td>
              <td style="text-align: center;">{{ item.alumnos }}</td>
              <td style="text-align: center;">{{ item.total }}</td>
            </tr>
          </tbody>
        </table>

        <!--Reembolsos-->
        <h1 style="margin-top: 50pt;">
          {{ $t("profesor.wallet.reqRefund") }}
          <button
            class="btn button buttonCreate"
            data-bs-toggle="modal"
            data-bs-target="#addModal"
            style="width: 140pt; background-color: crimson; color: white; height: 41px;"
          >
            <i class="fas fa-hand-holding-usd"></i>
            {{ $t("profesor.wallet.reqRefund") }}
          </button>
        </h1>
        <table class="table" v-if="refunds && refunds.length > 0">
          <thead>
            <tr>
              <th scope="col">{{ $t("profesor.wallet.date") }}</th>
              <th style="text-align: center;" scope="col">{{ $t("profesor.wallet.amount") }}</th>
              <th style="text-align: center;" scope="col">{{ $t("profesor.wallet.state") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in refunds" :key="item.usu_id">
              <td>{{ item.comi_fecha }}</td>
              <td style="text-align: center;">{{ item.comi_cantidad }}</td>
              <td style="text-align: center;">{{ item.comi_estado }}</td>
            </tr>
          </tbody>
        </table>
        <p style="text-align: right; margin-top: 40pt;">
          <b>{{ $t("profesor.wallet.totalBalance") }}:</b>{{ gastado }}/{{
            generado
          }} €
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      courses: [],
      courseComi: [],
      refunds: [],
      id_emp: this.$route.params.idemp,
      generado: "",
      gastado: "",
    };
  },
  created() {
    this.getcourses();
    this.getwallet();
    this.getrefunds();
    this.changeBreadCrumb(23);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor", "device"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listado`
        );
        console.log("miscursos", res);
        if (res.data) {
          this.courses = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getwallet() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=comisiones`
        );
        console.log("wallet", res);
        if (res.data) {
          this.courseComi = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getrefunds() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=reembolsos`
        );
        console.log("refund", res);
        if (res.data) {
          this.refunds = res.data.data;
          this.generado = this.refunds[0].generado;
          this.gastado = this.refunds[0].gastado;
        }
      } catch (error) {
        console.error(error);
      }
    },
    selectTheme(item) {
      this.course = [];
      this.course.push(item);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.btn {
  float: right;
}

.button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>